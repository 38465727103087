#media-item {
	.syndication-list {
		margin-top: 15px;
		margin-bottom: 15px;

		.media-item-viewer__show-all-syndications-toggle {
			cursor: pointer;
			margin-top: 5px;
			display: block;
		}

		.MuiTableCell-sizeSmall {
			padding-left: 0;
		}
		.MuiTableRow-root {
			td {
				border-bottom: none;
			}
		}
	}

	.item-id-container {
		margin-bottom: 25px;
		&__item-id {
			font-weight: 500;
			color: $primary-color;
			margin-bottom: 25px;
			cursor: pointer;
		}
	}

	.sentiment__clickableToEdit:hover {
		background: $border-foreground;
	}
}

.channel-option {
	display: flex;
	flex-direction: row;
	align-items: center;

	.media-type-icon {
		width: 20px;
		margin-right: #{$spacing-unit}px;
	}

	.media-type-label {
		margin: 0 4px;
	}
}
