.media-item-table {
	margin-top: #{$spacing-unit}px;
	//remove the filter row on media items as it's unused
	.dx-datagrid-filter-row {
		display: none;
	}

	.dx-datagrid .dx-row > td {
		line-height: unset;
	}

	.source-cell {
		display: flex;

		.appeared-count {
			margin-bottom: 3px;
		}

		.source-logo {
			.avatar {
				margin-left: 7.5px;
				margin-right: 7.5px;
			}
		}

		.syndications {
			display: flex;
			flex: 0 1 70px;
			.syndicated-items {
				flex: 0 1 auto;
				flex-wrap: wrap;
				display: flex;
				margin-right: 15px;
				& > span {
					display: inline;
				}
				span:nth-child(odd) {
					margin-bottom: 4px;
					margin-right: 5px;
				}
			}
		}
	}
}
