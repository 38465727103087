.query-condition-groups-builder {
	display: flex;
	flex-direction: column;
	> :not(:first-child) {
		margin-top: #{$spacing-unit * 2}px;
	}
	.select {
		.MuiTypography-root {
			font-size: $typography-body;
		}
		&__indicator {
			padding: 4px;
		}
	}
	.select-container {
		min-width: unset;
	}
}

.condition-group {
	display: flex;
	flex-direction: column;
	&__conditions {
		border: 1px solid $border-foreground;
		border-radius: 8px;
		background-color: $app-background-inset;
		margin-top: #{$spacing-unit * 2}px;
		box-sizing: border-box;
		padding: #{$spacing-unit * 2}px;
		position: relative;
		&__label {
			margin-top: -23px;
			position: absolute;
			text-transform: uppercase;
			font-size: 0.55rem;
			font-weight: bold;
			color: $font-light;
			border-radius: 8px;
			background-color: $app-background-inset;
			padding: 2px 4px;
		}
		&__include {
			.select {
				width: 100px;
			}
		}
	}
}

.condition {
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid $border-foreground;

	&__filter-function {
		margin-top: #{$spacing-unit * 2}px;
		align-items: top;
		display: flex;
		> .typography {
			margin-top: #{$spacing-unit}px;
		}
	}
	&__value {
		> .typography {
			margin-top: #{$spacing-unit}px;
		}
		margin-top: #{$spacing-unit * 2}px;
		align-items: top;
		flex-grow: 1;
		display: flex;
		> :first-child {
			display: flex;
			flex-grow: 1;
		}
	}
	&__tools {
		margin-top: #{$spacing-unit * 2}px;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: top;
		&__operator {
			display: none;
		}
	}
}

@include breakpoint-up(sm) {
	.condition {
		border-bottom: unset;
		flex-direction: row;
		&__field {
			width: 220px;
			flex-shrink: 0;
		}
		&__filter-function {
			width: 180px;
			flex-shrink: 0;
			margin-top: unset;
			margin-left: #{$spacing-unit * 2}px;
		}
		&__value {
			flex-grow: 1;
			margin-top: unset;
			margin-left: #{$spacing-unit * 2}px;
			.chip-textfield__container {
				background-color: #ffffff;
			}
		}
		&__tools {
			margin-left: #{$spacing-unit * 2}px;
			margin-top: unset;
			&__operator {
				display: block;
				width: 30px;
				margin-right: 0px;
				margin-left: 10px;
				margin-top: 12px;
			}
		}
	}
}

.multi-range-editor {
	display: flex;
	align-items: center;
	&__label-between {
		margin: 0 #{$spacing-unit * 2}px;
	}
}

.terms-async-select {
	&--source_source_id {
		//mick specifically requested that sources be full width
		.select__multi-value {
			width: 100%;
		}
	}
}
