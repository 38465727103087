@import '../../../theme.scss';
@import '../../../typography.scss';

#social-handles {
	th {
		color: $font-light;
	}
	.MuiGrid-grid-xs-12 {
		&:nth-last-child(2) {
			margin-bottom: 20px;
		}
	}

	.MuiLink-root {
		cursor: pointer;
		color: $font-color;
		&:hover {
			color: $font-color;
			text-decoration: none;
		}
	}
}
