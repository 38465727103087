@import '../../../theme.scss';

#entity {
	&.loading {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.typography--h2 {
		padding-bottom: 20px;
	}

	padding: #{$spacing-unit * 4}px;
}
