//units and sizes
$spacing-unit: 8;
$drawer-width: 240px;
$drawer-width-collapsed: 56px;
$overlay-transparency: 0.55;
$border-radius: 3px;

//colours
$black: #000000;
$white: #ffffff;
$grey-0: #f8f8fa;
$grey-1: #f1f1f3;
$grey-2: darken($grey-1, 1.5%);
$grey-3: darken($grey-1, 3.5%);
$grey-5: #8493a6;
$red-1: #d62637;
$red-2: #d43a45;
$green-1: #57ac2c;
$green-2: #3f9654;
$yellow-1: #ffa000;
$yellow-2: #cc8000;

$font-color: #2b4265;
$font-light: #8493a6;
$font-heading-color: rgb(0, 23, 55);
$primary-color: #0b5cce;

$primary-background: $primary-color; //#1976d2;
$primary-foreground: $white;
$primary-background-hover: darken($primary-color, 7%);
$primary-foreground-hover: $white;
$primary-foreground-disabled: rgba(255, 255, 255, 0.65);
$primary-background-disabled: desaturate($primary-color, 20%);

$secondary-background: $grey-5;
$secondary-foreground: $white;
$secondary-background-hover: darken($secondary-background, 7%);
$secondary-foreground-hover: $white;
$secondary-foreground-disabled: rgba(255, 255, 255, 0.65);
$secondary-background-disabled: desaturate($secondary-background, 20%);

$error-background: $red-1;
$error-foreground: $white;
$error-background-hover: darken($red-1, 7%);
$error-foreground-hover: $white;
$error-foreground-disabled: rgba(255, 255, 255, 0.65);
$error-background-disabled: desaturate($red-1, 20%);

$confirm-background: $green-1;
$confirm-foreground: $white;
$confirm-background-hover: darken($green-1, 7%);
$confirm-foreground-hover: $white;
$confirm-foreground-disabled: rgba(255, 255, 255, 0.65);
$confirm-background-disabled: desaturate($green-1, 20%);

$warning-background: $yellow-1;
$warning-foreground: $white;
$warning-background-hover: darken($yellow-1, 7%);
$warning-foreground-hover: $white;
$warning-foreground-disabled: rgba(255, 255, 255, 0.65);
$warning-background-disabled: desaturate($yellow-1, 20%);

$disabled-background: $grey-5;
$disabled-foreground: $white;
$disabled-background-hover: darken($grey-5, 7%);
$disabled-foreground-hover: $white;
$disabled-foreground-disabled: rgba(255, 255, 255, 0.65);
$disabled-background-disabled: desaturate($grey-5, 20%);

$table-toolbar-background: $white;
$table-toolbar-background-active: rgb(250, 224, 233);
$table-toolbar-foreground-active: rgb(220, 0, 78);

//colour usage
$overlay: rgba(0, 0, 0, 0.55);
$icon-foreground: $font-light;
$border-foreground: #e0e0e0;
$border-hover: rgba(0, 0, 0, 0.2);
$border-focus: #0b5cce;
$drawer-background: $white;
$field-background: $white;
$form-background: $white;
$app-background: $grey-1;
$app-background-inset: $grey-2;
$form-label: $grey-5;
