.iframe-container {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.external-graph {
		flex-grow: 1;
		border: none;
		margin: 0;
		padding: 0;
		height: 100vh;
	}
}
