.json-viewer {
	&__header {
		padding: #{$spacing-unit}px #{$spacing-unit * 2}px;
		display: flex;
		align-items: center;
	}
	&__body {
		display: none;
		&--open {
			display: block;
			background-color: $app-background-inset;
			border: 1px solid $border-foreground;
			border-radius: $border-radius;
			height: 800px;
			width: 100%;
		}
		.MuiInputBase-input {
			font-family: 'Courier New', Courier, monospace;
			tab-size: 30px;
		}
	}
}
