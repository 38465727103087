.client-users-table {
	margin: #{$spacing-unit * 2}px #{$spacing-unit * 8}px;
	.notification {
		&__content {
			text-align: center;
		}
	}
	.MuiInputBase-input,
	.MuiDataGrid-root {
		font-size: $typography-body;
	}
	.MuiDataGrid-columnHeader:focus,
	.MuiDataGrid-columnHeader:focus-within,
	.MuiDataGrid-cell:focus,
	.MuiDataGrid-cell:focus-within {
		outline: none;
	}

	.MuiDataGrid-columnSeparator {
		display: none;
	}
	&__name-cell {
		display: flex;
		align-items: center;
		&__impersonation-icon {
			border: 1px solid $border-hover;
			display: inline-flex;
			background: $app-background-inset;
			border-radius: 100%;
			height: 28px;
			width: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $icon-foreground;
			> svg {
				height: 16px;
				width: 16px;
			}
		}
		.MuiLink-root {
			color: $primary-color;
			padding-right: #{$spacing-unit}px;
		}
	}
}
.MuiButtonBase-root.MuiMenuItem-root {
	font-size: $typography-body;
}
