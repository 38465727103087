@import '../../../theme.scss';
@import '../../../typography.scss';

#entities-table {
	.no-results {
		text-align: center;
		padding: 80px 0;
	}

	.container {
		position: relative;
	}
	.loader {
		text-align: center;
		padding: 80px 0;
		position: absolute;
		background: $app-background;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.entities-toolbar {
		padding: 6px 24px 6px 10px;
		> div {
			padding-right: 25px;
		}
		a {
			font-size: $typography-body;
			text-decoration: none;
		}
	}

	a {
		cursor: pointer;
	}

	.MuiTableRow-root {
		border-top: 1px solid rgba(224, 224, 224, 1);

		th {
			padding-top: 13px;
			padding-bottom: 13px;
			color: $font-light;
			font-weight: normal;

			&.table-head-checkbox {
				width: 25px;
			}

			&.table-head-wiki {
				width: 30px;
				padding: 0;
			}
		}

		td {
			padding-top: 15px;
			padding-bottom: 15px;
			&.checkbox {
				padding: 0 0 0 10px;
			}
			&.name {
				cursor: pointer;
			}
			&.wiki {
				padding: 0;
				.wiki-link {
					display: block;
					padding-top: 2px;
					color: $font-light;
					&:hover {
						.MuiSvgIcon-fontSizeInherit {
							fill: $primary-color;
						}
					}
					&:visited {
						.MuiSvgIcon-fontSizeInherit {
							fill: $font-light;
						}
					}

					.MuiSvgIcon-fontSizeInherit {
						transition: fill 0.3s ease;
						font-size: 22px;
						margin-top: 2.5px;
					}
				}
			}
		}

		&.Mui-selected {
			background-color: $grey-3;
		}

		&.MuiTableRow-hover {
			&:hover {
				background-color: $grey-3;
			}
		}
	}

	.is-hidden {
		color: $font-light;
	}
}
