.queries-panel {
	flex: 1 1 100%;
	table {
		tr.queries-item {
			td {
				color: inherit;
				padding-top: 10px;
				padding-bottom: 0;
			}
		}

		tr.workspaces {
			border-bottom: 1px solid $border-foreground;

			.inline {
				flex-wrap: wrap;
				.inline {
					flex-wrap: unset;
					flex: 1 30%;
				}
			}

			&.empty {
				td {
					padding: 5px;
				}
			}

			td {
				padding-top: 5px;
				padding-top: 5px;
				border-bottom: none !important;
			}
		}
	}

	.query-options {
		padding: 16px;
		.panel {
			.select {
				flex-basis: 100%;
			}

			.inline--no-wrap > :not(:first-child) {
				margin-left: 0;
			}

			.buttons {
				display: flex;
				button {
					padding: 7px 7px 7px 10px;
					min-width: unset;
					border: 1px solid $border-foreground;
					&.Mui-disabled {
						background-color: $grey-2;
					}
					&.save {
						margin-right: 10px;
					}
					&.cancel {
						background-color: $grey-3;
					}
				}
			}
		}
	}
}
