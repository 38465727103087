.sign-in {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex: 1 1 0;

	&__logo {
		width: auto;
		height: 60px;
	}

	.panel {
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 50%;
	}

	&__right-panel {
		display: none;
		background-color: $white;
		width: 50%;
		&__display-iframe {
			position: absolute;
			border: none;
			height: 100%;
			width: 50%;
		}
	}
	&__left-panel {
		display: flex;
		background-color: $white;
		flex-grow: 1;
		box-sizing: border-box;
		&__content {
			max-width: 400px;
			&__sign-in-button {
				cursor: pointer;
				color: $white;
				font-size: 0.95rem;
				padding: 12.5px 15px;
				background-color: $primary-color;
				width: 100%;
				border: none;
				border-radius: 4px;
				&:disabled,
				&[disabled] {
					background-color: $disabled-background;
				}
			}
			&__loading {
				width: 100%;
				height: 5px;
			}
		}
	}
}

@include breakpoint-up(md) {
	.sign-in {
		&__right-panel {
			display: flex;
		}
	}
}
