@import '../../../theme.scss';

#entities-list {
	.textfield-container {
		flex: 1;
	}
	.select {
		flex: 0 0 175px;
	}

	#entities-pagination {
		text-align: center;
		button {
			border: 1px solid $primary-color;
			border-radius: 200px;
			padding: 5px 25px;
		}
	}

	button {
		text-transform: capitalize;
	}
}
