.dx-datagrid,
.dx-treelist {
	color: $font-color;
	background-color: transparent;
	.dx-row {
		td.cell {
			padding: 0;

			&--right {
				> div.wrap {
					display: flex;
					justify-content: flex-end;
				}
			}

			&--center {
				vertical-align: middle;
				text-align: center;
				div.wrap {
					width: 100%;
					display: flex;
					justify-content: center;
				}
			}

			&--pad {
				padding: #{$spacing-unit * 2}px #{$spacing-unit * 3}px #{$spacing-unit * 2}px #{$spacing-unit}px;
			}

			.typography--h6 {
				margin-bottom: 2px;
			}
		}
		.dx-datagrid-text-content,
		.dx-treelist-text-content {
			.treenode-parent {
				font-weight: $weight-bold;
			}
		}
	}

	.dx-header-row {
		background-color: darken($app-background, 2%);
		//checkboxes hover areas get trimmed without this
		.dx-datagrid-text-content,
		.dx-treelist-text-content {
			overflow: unset;
			font-weight: $weight-bold !important;
			font-size: $typography-h6;
		}
		td {
			border-bottom: 12px solid $border-foreground;
		}
	}

	.dx-treelist-headers .dx-treelist-table .dx-row > td:hover .dx-treelist-text-content,
	.dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover .dx-datagrid-text-content {
		color: $font-color !important;
	}

	.dx-selection.dx-row > td {
		background-color: transparent;
	}

	.dx-pager {
		padding: #{$spacing-unit * 3}px #{$spacing-unit * 1}px;
		border-top: 1px solid $border-foreground !important;
		margin-top: 0px !important;
		padding-top: #{$spacing-unit * 3}px;
		background-color: $app-background;
		border: 0px;
	}

	.dx-treelist-rowsview tr:not(.dx-row-focused) .dx-treelist-empty-space {
		color: $font-color;
		font-size: $typography-h5;
	}
	.dx-treelist-rowsview .dx-treelist-expanded span,
	.dx-treelist-rowsview .dx-treelist-collapsed span {
		font-size: $typography-h5;
	}

	.dx-header-row .dx-datagrid-text-content,
	.dx-header-row .dx-header-filter-indicator,
	.dx-column-indicators,
	.dx-treelist-headers {
		color: $font-color;
		font-weight: $weight-regular;
	}

	.dx-treelist-borders > .dx-treelist-rowsview.dx-last-row-border {
		border-bottom: 0px;
	}

	.dx-pager .dx-selection {
		background-color: $primary-color;
	}
}

.toolbar {
	display: flex;
	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: $border-foreground;
	background: $table-toolbar-background;
	padding: #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit}px #{$spacing-unit * 2}px;

	&--active {
		background: $table-toolbar-background-active;
		color: $table-toolbar-foreground-active;
	}

	> .toolbar {
		&__active-content {
			display: none;
			width: 100%;
		}
		&__inactive-content {
			display: flex;
			width: 100%;
		}
	}

	&--active {
		> .toolbar {
			&__active-content {
				display: flex;
			}
			&__inactive-content {
				display: none;
			}
		}
	}
}

.dx-loadpanel {
	&-content {
		background: $grey-0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.dx-loadindicator-segment-inner {
		border-color: $primary-color;
		border-bottom-color: transparent;
		border-right-color: transparent;
	}
	.dx-loadindicator-segment0 .dx-loadindicator-segment-inner {
		border-left-color: transparent;
	}
}

.dx-datagrid-headers .dx-datagrid-content,
.dx-treelist-headers .dx-treelist-content {
	background-color: $app-background;
}

.dx-datagrid-filter-row,
.dx-treelist-filter-row {
	background-color: $white;
}

.dx-datagrid-content .dx-datagrid-table,
.dx-treelist-content .dx-treelist-table {
	border-collapse: inherit;
}

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-treelist-borders > .dx-treelist-headers {
	border: 0px;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-datagrid-filter-row > td,
.dx-treelist .dx-treelist-content .dx-treelist-table .dx-treelist-filter-row > td {
	border-bottom: 2px solid $border-foreground;
	border-top: 0px;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
	border-bottom: 2px solid $border-foreground;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-datagrid-filter-row,
.dx-treelist .dx-treelist-content .dx-treelist-table .dx-treelist-filter-row {
	background-color: darken($app-background, 0%);
}

.dx-datagrid .dx-row-lines > td {
	border-bottom: 1px solid rgba($border-foreground, 0.5);
}

.dx-pager .dx-page,
.dx-widget {
	font-size: $typography-body;
	font-weight: $weight-semi;
}

.dx-datagrid-headers + .dx-datagrid-rowsview,
.dx-treelist-headers + .dx-treelist-rowsview {
	background-color: $app-background;
	border: 0px;
}
