.scopes-panel {
	flex: 1 1 100%;
	table {
		tr.scopes-item {
			td {
				color: inherit;
				padding-top: 10px;
				padding-bottom: 0;
			}
		}

		tr.workspaces {
			border-bottom: 1px solid $border-foreground;

			.inline {
				flex-wrap: wrap;
				.inline {
					flex-wrap: unset;
					flex: 1 30%;
				}
			}

			&.empty {
				td {
					padding: 5px;
				}
			}

			td {
				padding-top: 5px;
				padding-top: 5px;
				border-bottom: none !important;
			}
		}
	}

	.scope-options {
		padding: 16px;
		.panel {
			padding: 20px;
			border: 1px solid darken($grey-3, 4%);
			background-color: $grey-3;
			.buttons {
				margin-top: 30px;
				button {
					padding-left: 20px;
					padding-right: 20px;
					&.save {
						padding-left: 25px;
						padding-right: 25px;
						&.Mui-disabled {
							background-color: $grey-2;
						}
					}
					&.cancel {
						background-color: $grey-1;
					}
				}
			}
		}
	}

	.workspaces-panel {
		margin-top: 20px;

		.typography {
			&--subtitle {
				margin-bottom: 10px;
			}
		}

		.checkbox__control {
			padding: 9px 9px 9px 0;
		}
		.inline--no-wrap > :not(:first-child) {
			margin-left: 0;
		}
	}
}

.scopes-panel,
.queries-panel {
	.loading-indicator {
		margin: 0px;
		opacity: 1;
		div {
			max-width: 30px;
			max-height: 30px;
			background: $grey-0;
			-webkit-box-shadow: none;
			box-shadow: none;
			border: 1px solid $border-foreground;
			padding: 15px;
			border-radius: 200px;
		}
	}
}
