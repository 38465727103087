@import '../../../theme.scss';

#parent-dialog {
	.MuiPaper-root {
		background-color: #fff;
		padding: 15px;
		border-radius: 0;
	}

	.MuiDialogContent-root {
		&:first-child {
			padding-top: 10px;
		}
	}

	.MuiDialogActions-root {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.select {
		padding-top: 20px;

		[class$='-control'] {
			background-color: $grey-0;
		}
		[class$='-placeholder'] {
			color: $font-color;
		}
	}

	.typography--h1 {
		padding-bottom: 15px;
	}

	button {
		padding-left: 25px;
		padding-right: 25px;
		text-transform: capitalize;

		&.cancel {
			margin-right: 15px;
		}
		&.save {
			margin-right: 15px;

			&.is-saving {
				.MuiButton-label {
					line-height: 0;
				}
			}
		}
	}
}
