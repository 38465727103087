.social-handle {
	display: flex;
	border-style: solid;
	border-width: 1px;
	border-radius: 8px;

	&--registered {
		border-color: $confirm-background;
		background-color: lighten($confirm-background, 45%);
		.social-handle-icon > svg {
			fill: $confirm-background;
		}
	}

	&--unregistered {
		border-color: $warning-background;
		background-color: lighten($warning-background, 45%);
		.social-handle-icon > svg {
			fill: $warning-background;
		}
	}

	&--disabled {
		border-color: $secondary-background;
		background-color: lighten($secondary-background, 35%);
		.social-handle-icon > svg {
			fill: $secondary-background;
		}
	}

	box-sizing: border-box;
	padding: 6px 8px 6px 12px;

	&__container {
		display: flex;
		align-items: center;
		> :not(:first-child) {
			margin-left: 16px;
		}
		&--grow {
			flex-grow: 1;
		}
		&__button {
			> :first-child.button {
				width: 100px;
			}
		}
	}
}

.social-handle-icon {
	display: flex;
	align-items: center;
}
