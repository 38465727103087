$font-family: Roboto, 'IBM Plex Sans', sans-serif;
$typography-h1: 1.75rem; //28px
$typography-h2: 1.5rem; //24px
$typography-h3: 1.25rem; //20px
$typography-h4: 1.25rem; //24px
$typography-h5: 1.125rem; //18px
$typography-h6: 0.875rem; //14px
$typography-large: 1rem; //16px
$typography-body: 0.8125rem; //13px
$typography-small: 0.75rem; //12px
$typography-tiny: 0.55rem; //8px
$typography-icon-lg: 1.75rem;
$typography-icon-md: 1.25rem;
$typography-icon-sm: 0.875rem;
$typography-icon-xs: 0.5rem;
$weight-bolder: 800;
$weight-bold: 700;
$weight-semi: 500;
$weight-regular: 400;
$weight-light: 300;

h1 {
	color: $font-color;
}

.typography--body {
	color: $font-color;
	font-size: $typography-body;
}
