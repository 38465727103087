.search-autocomplete {
	&__listbox {
		li {
			background-color: #fff;
			max-height: 40px;
		}
		background-color: #fff;
		padding: 0px 0px 10px 0px;
		border-radius: 4px 4px 10px 10px;
		border-style: solid;
		border-width: 2px;
		border-color: rgb(207, 207, 207);
	}
	&__paper {
		border-radius: 2px 2px 10px 10px;
	}
}
// optional border colour: #3576cf
