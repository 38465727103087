@use 'sass:math';

.media-item-builder {
	.MuiOutlinedInput-root {
		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $border-foreground;
		}
		&:not(.Mui-focused):hover {
			.MuiOutlinedInput-notchedOutline {
				border-color: rgba(0, 0, 0, 0.2);
			}
		}
	}
	.Mui-focused {
		.MuiOutlinedInput-notchedOutline {
			border-color: $primary-color;
		}
	}

	.MuiInputBase-input {
		height: 21px;
	}
	.select-container {
		min-width: unset;
		.select__indicator {
			padding: #{math.div($spacing-unit, 2)}px;
		}
	}
}
