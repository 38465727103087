.workspace-content-rights {
	&__title {
		margin-bottom: #{$spacing-unit * 2}px;
	}

	&__list {
		background-color: $white;
		border: 1px solid $border-foreground;
		border-radius: 3px;
		position: relative;

		&__loading-overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: $white, $alpha: 0.7);
			z-index: 1;
		}

		&__item {
			&:hover {
				cursor: pointer;
			}
		}
	}
}
