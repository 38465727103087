.reactTable {
	box-shadow: unset;
	// See https://github.com/atlassian/react-beautiful-dnd/issues/362#issuecomment-712353246
	// React DND complains when the parent is scrollable.
	overflow-y: unset;
	overflow-x: unset;
	table {
		thead {
			th {
				padding: 12px 0;
			}
			tr {
				background-color: #ececee;
			}
		}
		tbody {
			tr {
				td {
					padding: 4px 8px;
					svg {
						padding-top: 6px;
					}
					button {
						padding: 10px;
					}
				}
			}
		}
	}
}
