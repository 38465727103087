@use 'sass:math';

.drawer {
	width: $drawer-width;
	background: $drawer-background;
	z-index: 1198;

	.overlay {
		position: fixed;
		top: 0;
		right: 0;
		height: 0;
		width: calc(100vw - #{$drawer-width});
		background-color: $overlay;
		transition: opacity 225ms ease-in-out, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, height 1ms step-start;
	}

	&--collapsed {
		width: $drawer-width-collapsed;
		.overlay {
			width: calc(100vw - #{$drawer-width-collapsed});
			opacity: 0;
			transition: opacity 225ms ease-in-out, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, height 255ms step-end;
		}
	}

	transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

	.paper {
		height: calc(100vh - 49px);
		transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		width: $drawer-width;
		overflow-x: hidden;
		overflow-y: auto;
		border: 0px;
		background: $drawer-background;
		.toolbar {
			padding: #{$spacing-unit}px;
			margin-bottom: #{$spacing-unit * 2}px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border: 0px;
			button svg {
				color: $primary-color !important;
			}
			.logo {
				margin-left: #{$spacing-unit * 2}px;
				height: 20px;
				width: 120px;
				transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
			}
			.logo-badge {
				margin: 0px 0px 0px 4px;
			}
		}

		&--collapsed {
			width: $drawer-width-collapsed;
			overflow-y: hidden;
			.toolbar {
				justify-content: center;
				.logo,
				.pin-drawer {
					width: 0;
					height: 0;
					margin: 0;
					display: none !important;
				}
				.logo-badge {
					margin: 8px 0px 0px 23px;
				}
			}

			.drawer__footer .drawer__item {
				padding-left: #{$spacing-unit * 1.2}px;
			}
			.drawer__item__icon {
				padding-left: math.div($spacing-unit, 4) px;
			}
		}
	}

	&__item {
		padding-left: #{$spacing-unit * 2}px;
		padding-right: #{$spacing-unit * 2}px;
		padding-top: #{math.div($spacing-unit, 4)}px;
		padding-bottom: #{math.div($spacing-unit, 4)}px;
		border-radius: $border-radius;

		&--selected {
			background-color: $app-background;
		}

		svg {
			color: $icon-foreground;
			font-size: 1rem;
			margin-right: #{$spacing-unit}px;
		}

		&__icon {
			display: inline-flex;
			min-width: #{$spacing-unit * 5}px;
			flex-shrink: 0;
			padding-left: #{$spacing-unit}px;

			> svg {
				font-size: $typography-icon-md;
			}
		}

		&__label {
			color: $font-color;
			font-size: $typography-h6;
			white-space: nowrap;
			font-weight: $weight-semi;
		}

		&--nested {
			padding-left: #{$spacing-unit * 2}px;

			.drawer__item__label {
				font-weight: $weight-regular;
				color: $font-light;
			}
		}

		&--selected.Mui-selected {
			background-color: $grey-1;
			border-radius: 0px;
			.drawer__item__label {
				color: $primary-color;
				font-weight: $weight-regular;
			}
		}
	}

	&__footer {
		background: $drawer-background;
		width: inherit;
		position: fixed;
		bottom: 0px;
		overflow: hidden;
		padding-top: #{$spacing-unit}px;
		padding-bottom: #{$spacing-unit}px;
		.drawer__item svg {
			margin-left: #{$spacing-unit}px;
		}
		.avatar {
			margin-right: #{$spacing-unit * 2}px;
		}
		.MuiCollapse-container {
			margin-top: #{$spacing-unit * 2}px;
		}
		.drawer__item__label {
			color: $font-color;
		}
	}

	.MuiCollapse-container {
		margin-bottom: #{$spacing-unit * 2}px;
	}
}

@include breakpoint-down(sm) {
	.app {
		> .drawer {
			position: absolute;
			.overlay {
				height: 100%;
			}
			&--collapsed {
				.overlay {
					height: 0;
				}
			}
		}
		> .view {
			margin-left: $drawer-width-collapsed;
		}
	}
}
