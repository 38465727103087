.workspace-scopes {
	&__title {
		margin-bottom: #{$spacing-unit * 2}px;
	}

	&__table {
		&__options {
			&__header {
				text-align: center;
				&__row {
					text-align: center;
				}
			}
		}
	}
}
