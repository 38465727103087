.source-logo-uploader {
	display: flex;
	.image-upload-cropper {
		&__container {
			height: 100px;
			width: 100px;
			background-color: unset;
		}
		&__error {
			background-color: white;
			border-radius: 50%;
		}
	}
	.image-upload {
		&__avatar-tools {
			right: 4px;
			bottom: 0px;
		}
	}
	.avatar {
		height: 96px;
		width: 96px;
		font-size: 2.5rem;
	}
}
