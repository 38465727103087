@import '../../../theme.scss';

#aliases {
	th {
		color: $font-light;
	}
	tr {
		td {
			color: $font-color;

			.remove {
				width: 25px;
				height: 25px;
			}

			.remove-loading {
				margin: 2px;
			}
		}
		&.parent {
			td {
				font-weight: bold;
			}
		}
	}
}
